<template>
  <div width="100%" min-height="100px" color="seperate-div">
    <div
      class="py-2"
      style="background: rgba(127, 145, 155, 0.052607); min-height: 40px"
    >
      <div class="d-flex align-center py-2">
        <div class="">
          <p class="titletext mb-0 ml-2 ml-sm-4 ml-md-8">Total Outstanding</p>
          <p class="value ml-sm-4 ml-md-8 mb-0 py-1">
            {{ count | currency(currencyWithShortCode) }}
          </p>
          <div class="chip px-4 py-1 ml-2 ml-sm-4 ml-md-6">{{ chipText }}</div>
        </div>
        <v-spacer></v-spacer>
        <div v-if="vendor">
          <!-- <p class="vendorTitle">Withholding</p>
          <p
            class="
              text-right text-caption
              px-2
              mt-1
              mb-sm-0 mb-md-0
              mr-sm-6 mr-md-8
              font-weight-bold
            "
          >
            {{ withHolding }}
          </p> -->
        </div>
        <div v-if="customer" class="text-right mr-2 mr-sm-4 mr-md-8">
          <p class="titletext">Account</p>
          <slot />
          <div class="bankChip px-4 py-1">{{ bankName }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import currencyFilter from "@/mixins/currencyFilter.js";
import countUp from "@/mixins/countUp";

export default {
  name: "contactDetailsCard",
  props: {
    vendor: {
      type: Boolean,
    },
    customer: {
      type: Boolean,
    },

    chipText: {
      type: String,
      default: "",
    },
    totalOutstanding: {
      type: [String, Number],
      default: 0,
    },
    bankName: {
      type: String,
      default: "",
    },
    withHolding: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  mixins: [currencyFilter, countUp],
  // mounted() {

  //   this.startCountUp(this.totalOutstanding);
  // },

  watch: {
    totalOutstanding: {
      deep: true,
      immediate: true,
      handler(val) {
        this.startCountUp(val);
      },
    },
  },
};
</script>

<style scoped>
p {
  margin-bottom: 0px;
}

.chip {
  background: #fdf9ef;
  color: #e3aa1c;
  border-radius: 8px;
  display: inline-block;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  letter-spacing: 1px;
}

.bankChip {
  background: #d9dee1;
  color: #78838f;
  border-radius: 10px;
  display: inline-block;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  letter-spacing: 1px;
}

.value {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: rgba(25, 40, 61, 0.8);
}

.titletext {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 10px;
  color: rgba(25, 40, 61, 0.8);
}

.vendorTitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #596a73;
}
</style>
