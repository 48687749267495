<template>
  <v-container class="mt-10">
    <v-row class="mx-2 mx-md-12">
      <v-col cols="12" sm="12" md="5">
        <v-card
          :min-height="`${$vuetify.breakpoint.smAndDown ? '1100px' : '789px'}`"
          elevation="3"
        >
          <v-card
            flat
            width="100%"
            style="border-bottom: 1px solid rgba(127, 145, 155, 0.3)"
          >
            <v-tabs v-model="pageTab" align-with-title slider-size="4">
              <v-tab href="#details" class="mt-2 tab">Details</v-tab>

              <v-spacer></v-spacer>

              <!-- <v-btn
                depressed
                outlined
                disabled
                small
                color="#2BD5AE"
                class="text-capitalize px-3 px-md-6 mt-3 mr-2 rounded"
                style="color: var(--v-primary-base)"
              >
                Block
              </v-btn> -->
              <v-btn
                @click="$emit('clicked')"
                outlined
                depressed
                small
                color="primary"
                class="text-capitalize px-4 mt-3 mr-2 mr-md-7 rounded"
                >Edit</v-btn
              >
            </v-tabs>
          </v-card>
          <div width="100%" height="100px" color="seperate-div">
            <ContactDetailsCard
              :vendor="vendor"
              :totalOutstanding="totalOutstanding"
              :withHolding="withHolding"
              chipText="Payable"
            />

            <div>
              <v-row class="mx-8 mt-6">
                <v-col class="pa-0" cols="12" md="4">
                  <v-subheader
                    class="primary--text px-0"
                    :style="{
                      fontSize: `${
                        $vuetify.breakpoint.smAndDown ? '0.86rem' : '1rem'
                      }`,
                    }"
                    >Company Name</v-subheader
                  >
                </v-col>
                <v-col cols="12" md="8" class="pa-0">
                  <v-text-field
                    class="px-0"
                    v-model="singleVendorDetails.company_name"
                    hide-details="auto"
                    readonly
                    solo
                    flat
                    dense
                    reverse
                    placeholder="Vendor Name"
                  ></v-text-field>
                </v-col>
                <v-divider
                  inset
                  style="border: 1px dashed rgba(48, 31, 120, 0.1)"
                ></v-divider>
              </v-row>
              <v-row class="mx-8 mt-6">
                <v-col class="pa-0" cols="12" md="4">
                  <v-subheader
                    class="primary--text font-weight-bolder px-0"
                    :style="{
                      fontSize: `${
                        $vuetify.breakpoint.smAndDown ? '0.86rem' : '1rem'
                      }`,
                    }"
                    >Contact Person</v-subheader
                  >
                </v-col>
                <v-col cols="12" md="8" class="pa-0">
                  <v-text-field
                    class="px-0"
                    v-model="singleVendorDetails.contact_full_name"
                    hide-details="auto"
                    readonly
                    solo
                    flat
                    dense
                    reverse
                    placeholder="Contact Full Name"
                  ></v-text-field>
                </v-col>
                <v-divider
                  inset
                  style="border: 1px dashed rgba(48, 31, 120, 0.1)"
                ></v-divider>
              </v-row>
              <v-row class="mx-8 mt-6">
                <v-col class="pa-0" cols="12" md="4">
                  <v-subheader
                    class="primary--text px-0"
                    :style="{
                      fontSize: `${
                        $vuetify.breakpoint.smAndDown ? '0.86rem' : '1rem'
                      }`,
                    }"
                    >Contact Email</v-subheader
                  >
                </v-col>
                <v-col cols="12" md="8" class="pa-0">
                  <v-text-field
                    class="px-0"
                    v-model="singleVendorDetails.contact_email"
                    hide-details="auto"
                    readonly
                    solo
                    flat
                    dense
                    reverse
                    placeholder="Enter Email"
                  ></v-text-field>
                </v-col>
                <v-divider
                  inset
                  style="border: 1px dashed rgba(48, 31, 120, 0.1)"
                ></v-divider>
              </v-row>
              <v-row class="mx-8 mt-6">
                <v-col class="pa-0" cols="12" md="4">
                  <v-subheader
                    class="primary--text px-0"
                    :style="{
                      fontSize: `${
                        $vuetify.breakpoint.smAndDown ? '0.86rem' : '1rem'
                      }`,
                    }"
                    >Contact Phone</v-subheader
                  >
                </v-col>
                <v-col cols="12" md="8" class="pa-0">
                  <v-text-field
                    class="px-0"
                    v-model="singleVendorDetails.contact_phone"
                    hide-details="auto"
                    readonly
                    solo
                    flat
                    dense
                    reverse
                    placeholder="Contact Phone"
                  ></v-text-field>
                </v-col>
                <v-divider
                  inset
                  style="border: 1px dashed rgba(48, 31, 120, 0.1)"
                ></v-divider>
              </v-row>
              <v-row class="mx-8 mt-6">
                <v-col class="pa-0" cols="12" md="4">
                  <v-subheader
                    class="primary--text px-0"
                    :style="{
                      fontSize: `${
                        $vuetify.breakpoint.smAndDown ? '0.86rem' : '1rem'
                      }`,
                    }"
                    >Accounting Code</v-subheader
                  >
                </v-col>
                <v-col cols="12" md="8" class="pa-0">
                  <v-text-field
                    class="px-0"
                    v-model="singleVendorDetails.accounting_code"
                    hide-details="auto"
                    readonly
                    solo
                    flat
                    dense
                    reverse
                    placeholder="Accounting Code"
                  ></v-text-field>
                </v-col>
                <v-divider
                  inset
                  style="border: 1px dashed rgba(48, 31, 120, 0.1)"
                ></v-divider>
              </v-row>
              <v-row class="mx-8 mt-6">
                <v-col class="pa-0" cols="12" md="4">
                  <v-subheader
                    class="primary--text px-0"
                    :style="{
                      fontSize: `${
                        $vuetify.breakpoint.smAndDown ? '0.86rem' : '1rem'
                      }`,
                    }"
                    >Default Terms</v-subheader
                  >
                </v-col>
                <v-col cols="12" md="8" class="pa-0">
                  <v-text-field
                    class="px-0"
                    v-model="singleVendorDetails.default_payment_terms"
                    hide-details="auto"
                    readonly
                    solo
                    flat
                    dense
                    reverse
                    placeholder="Default Terms"
                  ></v-text-field>
                </v-col>
                <v-divider
                  inset
                  style="border: 1px dashed rgba(48, 31, 120, 0.1)"
                ></v-divider>
              </v-row>
              <v-row class="mx-8 mt-6">
                <v-col class="pa-0" cols="12" md="4">
                  <v-subheader
                    class="primary--text px-0"
                    :style="{
                      fontSize: `${
                        $vuetify.breakpoint.smAndDown ? '0.86rem' : '1rem'
                      }`,
                    }"
                    >Tags</v-subheader
                  >
                </v-col>
                <v-col cols="12" md="8" class="pa-0 d-flex justify-end">
                  <div class="d-flex align-center">
                    <v-chip
                      class="mx-1 rounded-pill text-caption"
                      small
                      v-for="tag in singleVendorDetails.tags"
                      :key="tag"
                      style="
                        font-family: Lato;
                        font-size: 10px;
                        color: #2bd5ae;
                        background: #d5f7ef;
                      "
                    >
                      {{ tag }}
                    </v-chip>
                  </div>
                </v-col>
                <v-divider
                  inset
                  style="border: 1px dashed rgba(48, 31, 120, 0.1)"
                ></v-divider>
              </v-row>
              <v-row class="mx-8 mt-6">
                <v-col class="pa-0" cols="12" md="4">
                  <v-subheader
                    class="primary--text px-0"
                    :style="{
                      fontSize: `${
                        $vuetify.breakpoint.smAndDown ? '0.86rem' : '1rem'
                      }`,
                    }"
                    >Bank Name</v-subheader
                  >
                </v-col>
                <v-col cols="12" md="8" class="pa-0">
                  <v-text-field
                    class="px-0"
                    v-model="singleVendorDetails.bankname"
                    hide-details="auto"
                    readonly
                    solo
                    flat
                    dense
                    reverse
                    placeholder="Enter Bank Name"
                  ></v-text-field>
                </v-col>
                <v-divider
                  inset
                  style="border: 1px dashed rgba(48, 31, 120, 0.1)"
                ></v-divider>
              </v-row>
              <v-row class="mx-8 mt-6">
                <v-col class="pa-0" cols="12" md="4">
                  <v-subheader
                    class="primary--text px-0"
                    :style="{
                      fontSize: `${
                        $vuetify.breakpoint.smAndDown ? '0.86rem' : '1rem'
                      }`,
                    }"
                    >Bank Account</v-subheader
                  >
                </v-col>
                <v-col cols="12" md="8" class="pa-0">
                  <v-text-field
                    class="px-0"
                    v-model="singleVendorDetails.bank_account"
                    hide-details="auto"
                    readonly
                    solo
                    flat
                    dense
                    reverse
                    placeholder="Enter Bank Account"
                  ></v-text-field>
                </v-col>
                <v-divider
                  inset
                  style="border: 1px dashed rgba(48, 31, 120, 0.1)"
                ></v-divider>
              </v-row>
              <v-row class="mx-8 mt-6">
                <v-col class="pa-0" cols="12" md="4">
                  <v-subheader
                    class="primary--text px-0"
                    :style="{
                      fontSize: `${
                        $vuetify.breakpoint.smAndDown ? '0.86rem' : '1rem'
                      }`,
                    }"
                    >Date Added</v-subheader
                  >
                </v-col>
                <v-col cols="12" md="8" class="pa-0">
                  <v-text-field
                    v-model="singleVendorDetails.date_added"
                    hide-details="auto"
                    readonly
                    solo
                    flat
                    dense
                    reverse
                    style="
                      font-family: Inter;
                      font-style: normal;
                      font-weight: normal;
                      font-size: 15px;
                      line-height: 15px;
                      color: #7f919b;
                    "
                    placeholder="Enter Date"
                  >
                  </v-text-field>
                </v-col>
                <v-divider
                  inset
                  style="border: 1px dashed rgba(48, 31, 120, 0.1)"
                ></v-divider>
              </v-row>
              <div class="mx-8 mt-6">
                <v-switch
                  label="Approved"
                  color="#19283d"
                  v-model="singleVendorDetails.approved"
                  @change="handleVendorApproval"
                >
                </v-switch>
              </div>
            </div>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="7" class="">
        <!-- single contact analytics component starts -->

        <SingleContactAnalytics
          class="mb-8"
          cardTitle="Vendor Summary"
          :series="VendorAnalytics"
          :options="VendorOptions"
          :sumTotal="sumTotal"
          :loadingData="loadingData"
        />
        <!-- single contact analytics component ends -->
        <SingleContactTable>
          <template #tabHeader>
            <v-tabs v-model="tab" align-with-title slider-size="4">
              <v-tab
                class="mt-2 tab__class"
                v-for="item in vendorItems"
                :key="item.tab"
              >
                {{ item.tab }}
              </v-tab>
            </v-tabs>
            <v-spacer></v-spacer>
            <search @searchContent="sendSearchQuery" />
          </template>
          <template #table>
            <component
              v-bind:is="vendorItems[tab].content"
              :search-query="searchQuery"
              class="ml-0"
            ></component>
          </template>
        </SingleContactTable>

        <!-- tabs for vendor mobile devices -->
        <div class="px-0 mx-0" v-if="$vuetify.breakpoint.mdAndDown">
          <v-bottom-navigation fixed class="pa-0" dark>
            <v-tabs
              centered
              class="ma-0"
              background-color="primary"
              v-model="tab"
            >
              <v-tab
                class="mt-2 tabItems"
                v-for="item in items"
                :key="item.tab"
                >{{ item.tab }}</v-tab
              >
            </v-tabs>
          </v-bottom-navigation>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import vendorInvoice from "@/components/Contacts/vendor/vendor-invoice.vue";
import search from "@/components/BaseSearch.vue";
import vendorPayment from "@/components/Contacts/vendor/vendor-payment.vue";

import vendorScheduled from "@/components/Contacts/vendor/vendor-scheduled.vue";
import SingleContactTable from "./singleContactTable.vue";
import SingleContactAnalytics from "./singleContactAnalytics.vue";
import ContactDetailsCard from "./contactDetailsCard.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "SingleVendor",
  props: {
    // isVendor: {
    //   type: Boolean,
    //   default: false,
    // },
    totalOutstanding: {
      type: [Number, String],
      default: 0,
    },
    withHolding: {
      type: [Number, String],
      default: 0,
    },
  },
  data() {
    return {
      tab: 0,
      pageTab: "details",
      vendor: true,
      vendorItems: [
        { tab: "Invoices", content: "vendorInvoice" },
        { tab: "Payments", content: "vendorPayment" },
        // { tab: "Scheduled", content: "vendorScheduled" },
      ],
      singleVendorDetails: {
        company_name: "",
        contact_full_name: "",
        contact_email: "",
        contact_phone: "",
        accounting_code: "",
        default_payment_terms: "",
        tags: [],
        bankname: "",
        bank_account: "",
        date_added: null,
        approved: false,
      },

      VendorOptions: {
        dataLabels: {
          enabled: false,
        },
        colors: ["#E3AA1C", "#16BE98", "#FF6A6A"],
        labels: ["Invoices", "Payments"],

        plotOptions: {
          pie: {
            donut: {
              size: "80%",
              labels: {
                show: true,
                name: {
                  show: true,
                  fontSize: "22px",
                  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                  fontWeight: 600,
                  color: undefined,
                  offsetY: -10,
                },
                value: {
                  show: true,
                  fontSize: "22px",
                  color: "#596A73",
                  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                  fontWeight: 700,
                  //offsetY: 16,
                  formatter: function (val) {
                    return val;
                  },
                },
                total: {
                  show: true,
                  label: "Total",
                  color: "#8F96A1",
                  fontSize: "14px",
                  formatter: function (w) {
                    return w.globals.seriesTotals.reduce(function (a, b) {
                      return a + b;
                    }, 0);
                  },
                },
              },
              legend: {
                show: true,
                position: "right",
                horizontalAlign: "center",
                fontSize: "14px",
                fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                fontWeight: 400,
                labels: {
                  colors: "#8F96A1",
                },
                markers: {
                  width: 12,
                  height: 12,
                  strokeWidth: 0,
                  strokeColor: "#fff",
                  fillColors: ["#E3AA1C", "#16BE98", "#FF6A6A"],
                  radius: 12,
                  customHTML: undefined,
                  onClick: undefined,
                  offsetX: 0,
                  offsetY: 0,
                },
                itemMargin: {
                  horizontal: 1,
                },
                formatter: function (seriesName, opts) {
                  return (
                    seriesName + ":  " + opts.w.globals.series[opts.seriesIndex]
                  );
                },
              },
            },
          },
        },
        noData: {
          text: "No Data Available",
          align: "center",
          verticalAlign: "middle",
          offsetX: 0,
          offsetY: 0,
          style: {
            color: undefined,
            fontSize: "14px",
            fontFamily: undefined,
          },
        },
      },
      searchQuery: null,
      loadingData: false,
    };
  },
  computed: {
    ...mapGetters({
      vendorInvoices: "contacts/getVendorInvoices",
    }),

    VendorAnalytics() {
      return [this.vendorInvoices?.length, 0];
    },

    sumTotal() {
      return this.VendorAnalytics.reduce((acc, curVal) => acc + curVal, 0);
    },
  },
  components: {
    vendorInvoice,
    vendorPayment,
    vendorScheduled,
    SingleContactTable,
    SingleContactAnalytics,
    ContactDetailsCard,
    search,
  },
  methods: {
    // mapActions..
    ...mapActions({ showToast: "ui/showToast" }),
    formatDate(date) {
      return this.$moment(date).format("DD MMM YYYY");
    },

    // dispatch vuex action to fetch all the vendor invoices using try and catch
    async getVendorInvoices() {
      this.loadingData = true;
      const id = this.$route.params.id;
      try {
        await this.$store.dispatch("contacts/getVendorInvoices", id);
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingData = false;
      }
    },

    // pass the emitted search value to the searchQuery variable
    sendSearchQuery(val) {
      this.searchQuery = val;
    },

    // approve or disapprove a vendor...
    async handleVendorApproval() {
      const id = this.$route.params.id;

      try {
        await this.$store.dispatch("contacts/updateVendorDetails", {
          id,
          payload: { approved: this.singleVendorDetails.approved },
        });
        this.showToast({
          sclass: "success",
          show: true,
          message: "Vendor approved status changed Successfully",
          timeout: 3000,
        });
      } catch (error) {
        this.showToast({
          sclass: "error",
          show: true,
          message: "failed to approve vendor",
          timeout: 3000,
        });
      }
    },
  },
  mounted() {
    // console.log(this.$route.query.vendor);

    const id = this.$route.params.id;
    if (this.$route.query.vendor) {
      this.$store.dispatch("contacts/getSingleVendor", id).then((response) => {
        if (response.status == 200) {
          this.singleVendorDetails.company_name =
            response.data.vendorname || "";
          this.singleVendorDetails.contact_full_name =
            response.data.contact?.first_name +
              " " +
              response.data.contact?.last_name || "";
          this.singleVendorDetails.contact_email =
            response.data.contact?.email || "";
          this.singleVendorDetails.contact_phone =
            response.data.contact?.phone || "";
          this.singleVendorDetails.accounting_code = "";
          this.singleVendorDetails.default_payment_terms =
            response.data.terms || "";
          this.singleVendorDetails.tags =
            response.data.tag.map((str) => str.label) || [];
          this.singleVendorDetails.bankname = response.data.bankname || "";
          this.singleVendorDetails.bank_account =
            response.data.bankaccount || "";
        }
        this.singleVendorDetails.approved = response.data.approved || false;
        this.singleVendorDetails.date_added = this.formatDate(
          response.data.created_at
        );

        // this.vendorInvoices = response.data.invoices;
      });

      // this.getVendorInvoices(id);
    }

    this.getVendorInvoices();
  },
};
</script>

<style lang="scss" scoped>
.v-application--is-ltr .v-divider--inset:not(.v-divider--vertical) {
  margin-left: 0px;
}

.v-divider--inset:not(.v-divider--vertical) {
  max-width: calc(100% - 9px);
}

.v-application .elevation-3 {
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 3%), 0px 6px 10px 0px rgb(0 0 0 / 3%),
    0px 1px 18px 0px rgb(0 0 0 / 3%) !important;
}

.tabItems {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
}
</style>
