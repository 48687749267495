var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"pl-6 py-6",staticStyle:{"cursor":"pointer","transition":"all 0.3s ease","padding-right":"72px"},attrs:{"elevation":hover ? 2 : 0,"color":_vm.index % 2 ? '#f6f6f6' : ''},on:{"click":function($event){return _vm.$router.push({
          name: 'inbox.invoice',
          params: { id: _vm.id },
        })}}},[_c('v-layout',{staticClass:"align-center",attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"md1":""}},[_c('div',{staticClass:"d-flex justify-start align-center"},[_c('span',{staticClass:"text-truncate table__text"},[_vm._v(" "+_vm._s(_vm.index)+" ")])])]),_c('v-flex',{attrs:{"md3":""}},[_c('div',{staticClass:"d-flex justify-start align-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"text-truncate-left table__text"},'div',attrs,false),on),[_vm._v(" "+_vm._s(_vm.RefNumber)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.RefNumber))])])],1)]),_c('v-flex',{attrs:{"md2":""}},[_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"table__text"},[_c('v-icon',{attrs:{"small":"","color":_vm.statusIcon}},[_vm._v(" mdi-circle-medium ")]),_vm._v(" "+_vm._s(_vm.status)+" ")],1)])]),_c('v-flex',{attrs:{"md2":""}},[_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"text-truncate table__text",staticStyle:{"max-width":"110px"}},[_vm._v(_vm._s(_vm.created))])])]),_c('v-flex',{attrs:{"md2":""}},[_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"text-truncate table__text pl-2",staticStyle:{"max-width":"110px"}},[_vm._v(_vm._s(_vm._f("currency")(_vm.total,_vm.currencyNoSymbol)))])])]),_c('v-flex',{attrs:{"md2":""}},[_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"d-flex align-center mx-1"},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var hover = ref.hover;
return [_c('v-btn',{staticClass:"text-capitalize px-2 my-1 rounded",style:({
                    'background-color': hover ? '#ffffff' : '#d9dee1',
                    border: hover ? '' : '',
                    color: hover ? 'white' : '#ffffff',
                  }),attrs:{"dark":"","outlined":"","depressed":"","loading":_vm.isCanceling,"small":"","color":_vm.status === 'sent' ? 'grey' : 'primary'},on:{"click":function($event){$event.stopPropagation();return _vm.cancelCustomerInvoice.apply(null, arguments)}}},[_vm._v("Cancel")])]}}],null,true)})],1),_c('div',{staticClass:"d-flex align-center"},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var hover = ref.hover;
return [(_vm.status !== 'void')?_c('v-btn',{staticClass:"text-capitalize px-2 my-1 rounded",style:({
                    'background-color': hover ? '#c4c9cf' : '',
                    border: hover ? 'none' : '',
                    color: hover ? 'white' : '#ffffff',
                  }),attrs:{"dark":"","outlined":"","depressed":"","loading":_vm.isSending,"small":"","color":_vm.status === 'sent' ? 'grey' : 'primary'},on:{"click":function($event){$event.stopPropagation();return _vm.sendCustomerInvoice.apply(null, arguments)}}},[_vm._v("Send")]):_vm._e()]}}],null,true)})],1)])])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }