<template>
  <div>
    <v-hover v-slot:default="{ hover }">
      <v-card
        class="pl-6 py-6"
        style="cursor: pointer; transition: all 0.3s ease; padding-right: 72px"
        :elevation="hover ? 2 : 0"
        :color="index % 2 ? '#f6f6f6' : ''"
        @click="
          $router.push({
            name: 'inbox.invoice',
            params: { id: id },
          })
        "
      >
        <v-layout row wrap class="align-center">
          <v-flex md1>
            <div class="d-flex justify-start align-center">
              <span class="text-truncate table__text">
                {{ index }}
              </span>
            </div>
          </v-flex>
          <v-flex md3>
            <div class="d-flex justify-start align-center">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div
                    v-bind="attrs"
                    v-on="on"
                    class="text-truncate-left table__text"
                  >
                    {{ RefNumber }}
                  </div>
                </template>
                <span>{{ RefNumber }}</span>
              </v-tooltip>
            </div>
          </v-flex>

          <v-flex md2>
            <div class="d-flex align-center">
              <span class="table__text">
                <v-icon small :color="statusIcon"> mdi-circle-medium </v-icon>
                {{ status }}
              </span>
            </div>
          </v-flex>
          <v-flex md2>
            <div class="d-flex">
              <span
                class="text-truncate table__text"
                style="max-width: 110px"
                >{{ created }}</span
              >
            </div>
          </v-flex>
          <v-flex md2>
            <div class="d-flex">
              <span
                class="text-truncate table__text pl-2"
                style="max-width: 110px"
                >{{ total | currency(currencyNoSymbol) }}</span
              >
            </div>
          </v-flex>
          <!-- <v-flex md3>
            <div class="ms-4 d-flex justify-start">
              <span
               class="table__text"
                >{{ outstanding }}</span
              >
            </div>
          </v-flex> -->
          <v-flex md2>
            <div class="d-flex align-center">
              <div class="d-flex align-center mx-1">
                <v-hover v-slot="{ hover }">
                  <v-btn
                    @click.stop="cancelCustomerInvoice"
                    dark
                    outlined
                    depressed
                    :loading="isCanceling"
                    small
                    :color="status === 'sent' ? 'grey' : 'primary'"
                    :style="{
                      'background-color': hover ? '#ffffff' : '#d9dee1',
                      border: hover ? '' : '',
                      color: hover ? 'white' : '#ffffff',
                    }"
                    class="text-capitalize px-2 my-1 rounded"
                    >Cancel</v-btn
                  >
                </v-hover>
              </div>
              <div class="d-flex align-center">
                <v-hover v-slot="{ hover }">
                  <v-btn
                    v-if="status !== 'void'"
                    @click.stop="sendCustomerInvoice"
                    dark
                    outlined
                    depressed
                    :loading="isSending"
                    small
                    :color="status === 'sent' ? 'grey' : 'primary'"
                    :style="{
                      'background-color': hover ? '#c4c9cf' : '',
                      border: hover ? 'none' : '',
                      color: hover ? 'white' : '#ffffff',
                    }"
                    class="text-capitalize px-2 my-1 rounded"
                    >Send</v-btn
                  >
                </v-hover>
              </div>
            </div>
          </v-flex>
        </v-layout>
      </v-card>
    </v-hover>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import currencyFilter from "@/mixins/currencyFilter";
export default {
  name: "CustomerInvoiceCard",
  props: {
    index: {
      type: Number,
    },
    id: {
      type: Number,
    },
    RefNumber: {
      type: String,
    },
    status: {
      type: String,
    },
    total: {
      type: String,
    },
    created: {
      type: String,
    },
    outstanding: {
      type: String,
    },
    hypn_id: {
      type: String,
    },
  },
  data() {
    return {
      isSending: false,
      isCanceling: false,
    };
  },
  mixins: [currencyFilter],
  computed: {
    statusIcon() {
      if (this.status === "due") {
        return "#FF6A6A";
      } else if (this.status === "sent") {
        return "#16BE98";
      } else return "#e3aa1c";
    },
  },
  methods: {
    ...mapActions({ showToast: "ui/showToast" }),
    // sendCustomerInvoice() {
    //   this.$emit("sendCustomerInvoice", this.hypn_id);
    // },

    async cancelCustomerInvoice() {
      //   if (this.status === "sent") return;
      const payload = {
        invoice: this.hypn_id,
        cancel: true,
      };
      try {
        this.isCanceling = true;
        await this.$store.dispatch("contacts/cancelCustomerInvoice", payload);

        this.showToast({
          sclass: "success",
          show: true,
          message: "Invoice Cancelled!",
          timeout: 3000,
        });
      } catch (error) {
        console.log(error);
        this.showToast({
          sclass: "error",
          show: true,
          message: "Invoice Cancelled!",
          timeout: 3000,
        });
      } finally {
        this.isCanceling = false;
      }

      //   // console.log(JSON.stringify(payload, null, 2));
    },

    async sendCustomerInvoice() {
      if (this.status === "sent") return;
      const payload = {
        id: this.hypn_id,
        send: true,
      };
      try {
        this.isSending = true;
        await this.$store.dispatch("contacts/sendCustomerInvoice", payload);

        this.showToast({
          sclass: "success",
          show: true,
          message: "Invoice Sent!",
          timeout: 3000,
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.isSending = false;
      }

      // console.log(JSON.stringify(payload, null, 2));
    },
  },
};
</script>

<style scoped>
.v-application .elevation-2 {
  box-shadow: -6px 3px 1px -2px rgb(0 0 0 / 0%), 0px 2px 2px 0px rgb(0 0 0 / 0%),
    0px 1px 5px 0px rgb(0 0 0 / 7%) !important;
}

v-btn .button {
  transition-duration: 0.4s;
}

v-btn .button:hover {
  background-color: #19283d;
  color: white;
}

.table__text {
  font-family: "Inter";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #596a73;
}

.text-truncate-left {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  direction: rtl;
  /* Right-to-left direction */

  max-width: 100px;
}
</style>
