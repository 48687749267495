<template>
  <div class="d-flex align-center justify-center pt-4">
    <v-card
      max-height="40vh"
      min-width="100%"
      outlined
      class="px-6 py-10 rounded-lg"
    >
      <h5
        class="py-4 font-weight-bold text-subtitle-1 text-center primary--text"
      >
        {{ cardTitle }}: {{ refNumber }}
      </h5>

      <div class="d-flex align-center justify-space-between pb-4">
        <div>
          <p class="text-body-2 font-weight-bold grey--text mb-0">status</p>
          <v-chip class="pa-2 text-center" outlined x-small :color="chipColor"
            ><span class="text-caption px-2 ma-0">{{ status }}</span></v-chip
          >
        </div>
        <div>
          <p class="text-center text-body-2 font-weight-bold grey--text mb-0">
            Created
          </p>
          <v-chip class="px-2 py-3" x-small
            ><span class="text-caption px-2">{{ created }}</span></v-chip
          >
        </div>
        <div>
          <p class="text-right text-body-2 font-weight-bold grey--text mb-0">
            Amount
          </p>
          <v-chip class="px-2 py-3" x-small
            ><span class="text-caption px-2">{{
              total | currency(currencyWithShortCode)
            }}</span></v-chip
          >
        </div>
      </div>

      <!-- <template>
        <v-simple-table dense>
          <template v-slot:default>
            <thead class="px-0">
              <tr class="px-0">
                <th class="px-0 text-center primary--text text-subtitle-2">
                  Outstanding (N)
                </th>
              </tr>
            </thead>
            <tbody class="px-2">
              <tr class="px-2">
                <td class="px-2 text-center">
                  {{ outstanding }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </template> -->
      <div
        class="d-flex align-center justify-end"
        v-if="cardTitle == 'Invoices'"
      >
        <div class="d-flex align-center mx-1">
          <v-hover v-slot="{ hover }">
            <v-btn
              @click.stop="cancelCustomerInvoice"
              dark
              outlined
              depressed
              :loading="isCanceling"
              small
              :color="status === 'sent' ? 'grey' : 'primary'"
              :style="{
                'background-color': hover ? '#ffffff' : '#d9dee1',
                border: hover ? '' : '',
                color: hover ? 'white' : '#ffffff',
              }"
              class="text-capitalize px-2 my-1 rounded"
              >Cancel</v-btn
            >
          </v-hover>
        </div>
        <div class="d-flex align-center mx-1" v-if="status !== 'void'">
          <v-hover v-slot="{ hover }">
            <v-btn
              @click="sendCustomerInvoice"
              dark
              outlined
              depressed
              :loading="isSending"
              small
              :color="status === 'sent' ? 'grey' : 'primary'"
              :style="{
                'background-color': hover ? '#c4c9cf' : '',
                border: hover ? 'none' : '',
                color: hover ? 'white' : '#ffffff',
              }"
              class="text-capitalize px-2 my-1 rounded"
              >Send</v-btn
            >
          </v-hover>
        </div>
      </div>
      <div
        class="d-flex align-center justify-end"
        v-if="cardTitle == 'payments'"
      >
        <p class="text-right text-body-2 font-weight-bold grey--text mb-0">
          Amount
        </p>
        <v-chip class="px-2 py-3" x-small
          ><span class="text-caption px-2">{{ amount }}</span></v-chip
        >
      </div>
    </v-card>
  </div>
</template>

<script>
import currencyFilter from "@/mixins/currencyFilter";

export default {
  name: "mobileTableCard",
  props: {
    index: {
      type: Number,
    },
    id: {
      type: Number,
    },
    outstanding: {
      type: String,
    },
    refNumber: {
      type: String,
    },
    cardTitle: {
      type: String,
    },
    dueDate: {
      type: String,
    },
    total: {
      type: String,
    },
    created: {
      type: String,
    },
    status: {
      type: String,
    },
  },
  mixins: [currencyFilter],
  data() {
    return {
      isSending: false,
      isCanceling: false,
    };
  },
  computed: {
    chipColor() {
      if (this.status === "void") {
        return "#FF6A6A";
      } else if (this.status === "due") {
        return "#F7CA40";
      } else return "#2BD5AE";
    },
  },

  methods: {
    async sendCustomerInvoice() {
      if (this.status === "sent") return;
      const payload = {
        id: this.hypn_id,
        send: true,
      };
      try {
        this.isSending = true;
        await this.$store.dispatch("contacts/sendCustomerInvoice", payload);

        this.showToast({
          sclass: "success",
          show: true,
          message: "Invoice Sent!",
          timeout: 3000,
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.isSending = false;
      }

      console.log(JSON.stringify(payload, null, 2));
    },

    async cancelCustomerInvoice() {
      //   if (this.status === "sent") return;
      const payload = {
        invoice: this.hypn_id,
        cancel: true,
      };
      try {
        this.isCanceling = true;
        await this.$store.dispatch("contacts/cancelCustomerInvoice", payload);

        this.showToast({
          sclass: "success",
          show: true,
          message: "Invoice Cancelled!",
          timeout: 3000,
        });
      } catch (error) {
        console.log(error);
        this.showToast({
          sclass: "error",
          show: true,
          message: "Invoice Cancelled!",
          timeout: 3000,
        });
      } finally {
        this.isCanceling = false;
      }

      //   // console.log(JSON.stringify(payload, null, 2));
    },
  },
};
</script>

<style>
/* .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  padding: 8px !important;
} */
</style>
