<template>
  <div>
    <div class="d-flex align-center px-10 pt-4">
      <div class="ring__container">
        <img src="@/assets/icons/receipt-2.png" />
      </div>
    </div>
    <p class="px-10 pt-6 pb-10">
      Sorry, there is no payment data for this entity
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.ring__container {
  position: relative;
  width: 180px;
  height: 180px;
  border-radius: 50%;
  border: 24px solid #f6f3ee;
}

img {
  width: 50px;
  height: 50px;
  position: absolute;
  top: 40px;
  left: 40px;
}

p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 15px;
  color: #525e6e;
}
</style>
