<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-row align="center" v-if="$vuetify.breakpoint.mdAndUp">
          <v-col class="d-flex justify-center align-center" cols="12">
            <h3
              class="font-weight-bold text-md-h4 primary--text pl-md-14"
              style="line-height: 39px"
            >
              Contacts
              <span
                ><v-icon large class="text--disabled"
                  >mdi-chevron-right</v-icon
                ></span
              >
              <span v-if="$route.query.vendor" class="subtitle">vendor</span>
              <span v-else class="subtitle">customer</span>
            </h3>

            <v-spacer></v-spacer>

            <ContactDropDown
              btnText="Add New"
              icon="contact"
              width="148"
              height="54px"
              style="margin-right: 88px"
            />
          </v-col>
        </v-row>

        <!-- page title for mobile screens -->
        <v-row align="center" v-if="$vuetify.breakpoint.smAndDown">
          <v-col class="d-flex justify-center align-center" cols="12">
            <h3
              class="mt-6 font-weight-bold text-h5 primary--text pl-2"
              style="line-height: 18px"
            >
              Contacts
              <span
                ><v-icon class="text--disabled">mdi-chevron-right</v-icon></span
              >
              <span v-if="$route.query.vendor" class="ms-2 ms-md-1 text-h6"
                >vendor</span
              >
              <span v-else class="ms-2 ms-md-1 text-h6">customer</span>
            </h3>

            <v-spacer></v-spacer>

            <ContactDropDown
              btnText="Add New"
              icon="contact"
              width="148"
              height="54px"
              style="margin-right: 88px"
              class="mr-2"
            />
          </v-col>
        </v-row>
      </v-col>
      <!-- for Vendors -->

      <SingleVendorsPage
        v-if="$route.query.vendor"
        :totalOutstanding="totalOutstanding"
        :withHolding="withHolding"
        @clicked="editVendor"
      />
      <!-- for customers -->

      <SingleCustomerPage v-else @clicked="editCustomer" />
    </v-row>
    <edit-vendor ref="EditVendor" />
    <edit-customer ref="EditCustomer" />
  </v-container>
</template>

<script>
import ContactDropDown from "@/includes/ContactDropdown";
import EditVendor from "../../includes/overlays/ContactsMenuModals/EditVendor.vue";
import EditCustomer from "../../includes/overlays/ContactsMenuModals/EditCustomer.vue";
import SingleCustomerPage from "./components/singleCustomerPage.vue";
import SingleVendorsPage from "./components/singleVendorsPage.vue";

export default {
  name: "singleContact",
  data() {
    return {
      vendorInvoices: [],
      menu: false,
      //  tab1: 0,
      tab: 0,
      vendor: true,
      customer: true,
      withHolding: "23,000.00",
      isVendor: this.$route.query.vendor,
      isEditVendor: true,
      disabledField: true,
      contactRecord: "234 Records",
      totalOutstanding: "460,000.00",
      // bankName: "bank name",
      // CustomerWalletNumber: "3092829202",

      singleCustomerDetails: {
        company_name: "",
        contact_person: "",
        contact_email: "",
        contactPhone: "",
        accounting_code: "",
        default_payment_terms: "",
        tags: [],
      },

      contact_first_name: "",
      contact_last_name: "",

      vendorItems: [
        { tab: "Invoices", content: "vendorInvoice" },
        { tab: "Payments", content: "vendorPayment" },
        { tab: "Mandates", content: "vendorMandate" },
      ],
    };
  },
  components: {
    ContactDropDown,
    EditVendor,
    EditCustomer,
    SingleCustomerPage,
    SingleVendorsPage,
  },
  methods: {
    editVendor() {
      this.$refs.EditVendor.show(this.$route.query.edit || true);
      // console log the route query

      console.log(this.$route.query.edit);
    },
    editCustomer() {
      this.$refs.EditCustomer.show(this.$route.query.edit || true);
      // console.log(this.$route.query.edit);
    },
    formatDate(date) {
      const options = { year: "numeric", month: "numeric", day: "numeric" };
      return new Date(date).toLocaleDateString("en-GB", options);
    },
  },

  computed: {
    backgroundColor() {
      return this.disabledField ? "transparent" : "#ffffff";
    },
  },
  mounted() {
    // console.log(this.$route);
    if (!this.$route.query.vendor && this.$route.query.edit) {
      this.editCustomer();
    } else if (this.$route.query.vendor && this.$route.query.edit) {
      this.editVendor();
    }
  },
};
</script>

<style scoped>
.subtitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 39px;
  color: #8f96a1;
}

.tab {
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
}

.seperate-div {
  background: rgba(127, 145, 155, 0.052607);
}

.small-chip {
  font-family: "Lato";
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 3px;
  background: #f9eed2;

  color: #e3aa1c;
}

.v-chip.v-size--x-small {
  border-radius: 8px;
  font-size: 10px;
  height: 10px;
}

.theme--light.v-chip:not(.v-chip--active) {
  background: #f9eed2;
}

v-chip.v-size--x-small[data-v-2900d98a] {
  border-radius: 8px;
  font-size: 10px;
  height: 10px;
}

.chipNormal {
  font-family: "Lato" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 10px !important;
  line-height: 10px !important;
  background: #d8d8d8;
  border-radius: 6px;
  color: #596a73 !important;
}

.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
  > .v-input__control
  > .v-input__slot,
.v-text-field.v-text-field--enclosed .v-text-field__details {
  padding: 0 1px;
}

.v-application--is-ltr .v-divider--inset:not(.v-divider--vertical) {
  margin-left: 1px;
}

.v-divider--inset:not(.v-divider--vertical) {
  max-width: calc(100% - 5px);
}

.v-application .elevation-3 {
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 3%), 0px 6px 10px 0px rgb(0 0 0 / 3%),
    0px 1px 18px 0px rgb(0 0 0 / 3%) !important;
}

.v-tabs:not(.v-tabs--vertical):not(.v-tabs--right)
  > .v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes)
  .v-slide-group__prev {
  /* display: initial; */
  visibility: hidden;
}

.v-subheader {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 8px;
  line-height: 15px;
  color: #19283d;
}

.v-btn--icon.v-size--default {
  height: 18px;
  width: 18px;
}
</style>
