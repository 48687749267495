<template>
  <div class="d-flex justify-end align-center px-3">
    <!-- <v-text-field v-model="search" label="Search" outlined @input="filterList" />
      <v-list>
        <v-list-item v-for="(item, index) in filteredList" :key="index">
          <v-list-item-title>{{ item }}</v-list-item-title>
        </v-list-item>
      </v-list> -->

    <v-text-field
      dense
      v-if="showInput"
      v-model="search"
      placeholder="Search"
      hide-details="true"
      @input="handleSearchEmit"
    />
    <v-icon class="search-icon float-end" @click="showInput = !showInput">{{
      showInput ? "mdi-close" : "mdi-magnify"
    }}</v-icon>
  </div>
</template>

<script>
export default {
  name: "searchComponent",

  data() {
    return {
      search: "",
      showInput: false,
    };
  },

  methods: {
    handleSearchEmit() {
      this.$emit("searchContent", this.search);
      console.log(this.search);
    },
  },
};
</script>

<style scoped>
.search-icon {
  cursor: pointer;
}
</style>
