<template>
  <div class="portal_wrapper">
    <v-card elevation="3">
      <div
        class="
          d-block d-sm-flex
          align-center
          justify-space-between
          px-4
          py-3
          flex-shrink-0
        "
      >
        <p class="link_title py-2 py-sm-0 d-flex flex-shrink-0">
          Balance Portal
        </p>
        <div
          class="
            link_container
            d-inline-flex
            justify-space-between
            align-center
            px-2
          "
          style="width: 75%"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <p class="link_text text-truncate pl-2" v-bind="attrs" v-on="on">
                {{ linkText }}
              </p>
            </template>
            <span>{{ linkText }}</span>
          </v-tooltip>
          <v-btn small icon @click="$emit('sendInvite')">
            <img
              :src="require('@/assets/pbot_icons/portal-link.png')"
              alt="portal-link icon"
              style="width: 13px; height: 13px"
            />
          </v-btn>
          <v-btn small icon @click="copy">
            <v-icon x-small class="px-2">mdi-content-copy</v-icon>
          </v-btn>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    linkText: String,
  },
  methods: {
    ...mapActions({ showToast: "ui/showToast" }),

    copy() {
      const value = this.linkText;
      this.$clipboard(value).then(
        this.showToast({
          sclass: "success",
          show: true,
          message: `copied ${value} to clipboard`,
          timeout: 3000,
        })
      );
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  margin-bottom: 0px;
}

.v-application .elevation-3 {
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 3%), 0px 6px 10px 0px rgb(0 0 0 / 3%),
    0px 1px 18px 0px rgb(0 0 0 / 3%) !important;
}

.link_container {
  background: #f4f5f6;
  // opacity: 0.3;
  border-radius: 8px;
}

.link_text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: rgba(25, 40, 61, 0.8);
}

.link_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #19283d;
}
</style>
