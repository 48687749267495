<template>
  <div>
    <v-hover v-slot:default="{ hover }">
      <v-card
        class="px-6 py-6 d-flex align-center"
        style="cursor: pointer; transition: all 0.3s ease"
        :elevation="hover ? 2 : 0"
        :color="index % 2 ? '#f6f6f6' : ''"
      >
        <v-layout row wrap class="d-flex align-center ml-1">
          <!-- <v-flex md1 class="d-flex justify-start px-0">
            <span
              class="mb-0 mr-1 pa-0 table__text"
              
              >{{ id }}</span
            >
          </v-flex> -->
          <v-flex md3>
            <div class="d-flex justify-start align-center">
              <div class="table__text text-truncate" style="max-width: 120px">
                {{ RefNumber }}
              </div>
            </div>
          </v-flex>

          <v-flex md3>
            <div class="d-flex align-center">
              <span class="table__text">
                <v-icon small :color="stausIcon"> mdi-circle-medium </v-icon>
                {{ status }}</span
              >
            </div>
          </v-flex>
          <v-flex md3>
            <div class="d-flex">
              <span
                class="text-truncate table__text"
                style="max-width: 110px"
                >{{ dueDate }}</span
              >
            </div>
          </v-flex>

          <v-flex md3>
            <div class="ms-4 d-flex justify-start">
              <span class="table__text">{{ amount }}</span>
            </div>
          </v-flex>
        </v-layout>
      </v-card>
    </v-hover>
  </div>
</template>

<script>
export default {
  name: "customerPaymentCard",
  props: {
    index: {
      type: Number,
    },
    id: {
      type: Number,
    },
    RefNumber: {
      type: String,
    },
    status: {
      type: String,
    },
    dueDate: {
      type: String,
    },
    amount: {
      type: String,
    },
  },
  data() {
    return {};
  },

  computed: {
    stausIcon() {
      if (this.status === "due") {
        return "#FF6A6A";
      } else if (this.status === "sent") {
        return "#F7CA40";
      } else return "#2BD5AE";
    },
  },
};
</script>

<style scoped>
.v-application .elevation-2 {
  box-shadow: -6px 3px 1px -2px rgb(0 0 0 / 0%), 0px 2px 2px 0px rgb(0 0 0 / 0%),
    0px 1px 5px 0px rgb(0 0 0 / 7%) !important;
}

v-btn .button {
  transition-duration: 0.4s;
}

v-btn .button:hover {
  background-color: #19283d;
  color: white;
}

.table__text {
  font-family: "Inter";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #596a73;
}
</style>
