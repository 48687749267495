import { render, staticRenderFns } from "./singleCustomerPage.vue?vue&type=template&id=2ea7a3b8&scoped=true&"
import script from "./singleCustomerPage.vue?vue&type=script&lang=js&"
export * from "./singleCustomerPage.vue?vue&type=script&lang=js&"
import style0 from "./singleCustomerPage.vue?vue&type=style&index=0&id=2ea7a3b8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ea7a3b8",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBottomNavigation } from 'vuetify/lib/components/VBottomNavigation';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
installComponents(component, {VBottomNavigation,VBtn,VCard,VChip,VCol,VContainer,VDivider,VIcon,VRow,VSpacer,VTab,VTabs})
