<template>
  <v-container class="mt-10">
    <v-row class="mx-2 mx-md-12">
      <v-col cols="12" sm="12" md="5">
        <v-card min-height="640px" elevation="3">
          <v-card
            flat
            width="100%"
            style="border-bottom: 1px solid rgba(127, 145, 155, 0.3)"
          >
            <v-tabs align-with-title slider-size="4">
              <v-tab class="mt-2 tab">Details</v-tab>
              <v-spacer></v-spacer>
              <!-- <v-btn depressed disabled outlined small color="#2BD5AE" class="text-capitalize px-3 px-md-4 mt-3 mr-2 rounded"
                style="color: var(--v-primary-base)">
                Block
              </v-btn> -->
              <v-btn
                @click="$emit('clicked')"
                outlined
                depressed
                small
                color="primary"
                class="text-capitalize px-4 mt-3 mr-2 mr-md-7 rounded"
                >Edit</v-btn
              >
            </v-tabs>
          </v-card>
          <ContactDetailsCard
            :customer="customer"
            chipText="Receivable"
            :totalOutstanding="totalOutstanding"
            :bankName="bankname"
          >
            <div class="d-flex align-center account">
              <v-btn icon @click="toggleMask">
                <v-icon small>{{ mask ? "mdi-eye" : "mdi-eye-off" }}</v-icon>
              </v-btn>
              <span>{{ maskedAccountnumber }}</span>
            </div>
          </ContactDetailsCard>
          <div width="100%" min-height="100px" color="seperate-div">
            <!-- form field details -->
            <div class="mt-md-8">
              <v-row class="mx-8 mt-4">
                <v-col class="pa-0 d-flex align-center" cols="12" md="5">
                  <p class="header__txt">ID</p>
                </v-col>
                <v-col cols="12" md="7" class="text-md-right">
                  <p class="value__txt">
                    {{ singleCustomerDetails.id }}
                  </p>
                </v-col>
                <v-divider
                  inset
                  style="border: 1px dashed rgba(48, 31, 120, 0.1)"
                ></v-divider>
              </v-row>
              <v-row class="mx-8 mt-4">
                <v-col class="pa-0 d-flex align-center" cols="12" md="5">
                  <p class="header__txt">Company</p>
                </v-col>
                <v-col cols="12" md="7" class="text-md-right">
                  <p class="value__txt">
                    {{ singleCustomerDetails.company_name }}
                  </p>
                </v-col>
                <v-divider
                  inset
                  style="border: 1px dashed rgba(48, 31, 120, 0.1)"
                ></v-divider>
              </v-row>
              <v-row class="mx-8 mt-4">
                <v-col class="pa-0 d-flex align-center" cols="12" md="3">
                  <p class="header__txt">Contact</p>
                </v-col>
                <v-col cols="12" md="9" class="text-md-right">
                  <p class="value__txt">
                    {{ singleCustomerDetails.contact_person }}
                  </p>
                </v-col>
                <v-divider
                  inset
                  style="border: 1px dashed rgba(48, 31, 120, 0.1)"
                ></v-divider>
              </v-row>
              <v-row class="mx-8 mt-4">
                <v-col class="pa-0 d-flex align-center" cols="12" md="3">
                  <p class="header__txt">Email</p>
                </v-col>
                <v-col cols="12" md="9" class="text-md-right">
                  <p class="value__txt">
                    {{ singleCustomerDetails.contact_email }}
                  </p>
                </v-col>
                <v-divider
                  inset
                  style="border: 1px dashed rgba(48, 31, 120, 0.1)"
                ></v-divider>
              </v-row>
              <v-row class="mx-8 mt-4">
                <v-col class="pa-0 d-flex align-center" cols="12" md="5">
                  <p class="header__txt">Contact Phone</p>
                </v-col>
                <v-col cols="12" md="7" class="text-md-right">
                  <p class="value__txt">
                    {{ singleCustomerDetails.contactPhone }}
                  </p>
                </v-col>
                <v-divider
                  inset
                  style="border: 1px dashed rgba(48, 31, 120, 0.1)"
                ></v-divider>
              </v-row>
              <v-row class="mx-8 mt-4">
                <v-col class="pa-0 d-flex align-center" cols="12" md="4">
                  <p class="header__txt">Tags</p>
                </v-col>
                <v-col cols="12" md="8" class="text-md-right">
                  <div class="d-flex align-center flex-wrap">
                    <v-chip
                      class="ma-1 rounded-pill text-caption"
                      small
                      v-for="tag in singleCustomerDetails.tags"
                      :key="tag"
                      style="
                        font-family: inter;
                        color: #2bd5ae;
                        background: #d5f7ef;
                      "
                    >
                      {{ tag }}
                    </v-chip>
                  </div>
                </v-col>
                <v-divider
                  inset
                  style="border: 1px dashed rgba(48, 31, 120, 0.1)"
                ></v-divider>
              </v-row>
              <v-row class="mx-8 mt-4">
                <v-col class="pa-0 d-flex align-center" cols="12" md="5">
                  <p class="header__txt">Default Terms</p>
                </v-col>
                <v-col cols="12" md="7" class="text-md-right">
                  <p class="value__txt">
                    {{ singleCustomerDetails.defaultPaymentTerms }}
                  </p>
                </v-col>
                <v-divider
                  inset
                  style="border: 1px dashed rgba(48, 31, 120, 0.1)"
                ></v-divider>
              </v-row>
            </div>
            <!-- <pre>{{ customerBankDetails }}</pre> -->
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="7" class="">
        <!-- single contact Balance portal link starts-->
        <BalancePortalLink
          :linkText="balancePortalLinkText"
          class="mb-8"
          @sendInvite="sendLink"
        />
        <!-- single contact Balance portal link ends-->

        <!-- single contact analytics component starts -->
        <SingleContactAnalytics
          :series="customerAnalytics"
          :options="CustomerOptions"
          :sumTotal="sumTotal"
          :loadingData="loadingData"
          class="mb-8"
          cardTitle="Customer Summary"
        />
        <!-- single contact analytics component ends -->
        <!-- single contact table component starts -->
        <SingleContactTable :tab="tab">
          <template #tabHeader>
            <v-tabs v-model="tab" align-with-title slider-size="4">
              <v-tab
                class="mt-2 tab__class"
                v-for="item in items"
                :key="item.tab"
              >
                {{ item.tab }}
              </v-tab>
            </v-tabs>
            <v-spacer></v-spacer>
            <search @searchContent="sendSearchQuery" />
          </template>
          <template #table>
            <component
              v-bind:is="items[tab].content"
              :search-query="searchQuery"
              class="ml-0"
            ></component>
          </template>
        </SingleContactTable>
        <!-- single contact table component starts -->
        <!-- tabs for customer mobile devices -->
        <div class="px-0 mx-0" v-if="$vuetify.breakpoint.mdAndDown">
          <v-bottom-navigation fixed class="pa-0" dark>
            <v-tabs
              centered
              class="ma-0"
              background-color="primary"
              v-model="tab"
            >
              <v-tab
                class="mt-2 tabItems"
                v-for="item in items"
                :key="item.tab"
                >{{ item.tab }}</v-tab
              >
            </v-tabs>
          </v-bottom-navigation>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ContactDetailsCard from "./contactDetailsCard.vue";
import SingleContactAnalytics from "./singleContactAnalytics.vue";
import CustomerInvoice from "@/components/Contacts/Customer/Customer-Invoice.vue";
import CustomerPayment from "@/components/Contacts/Customer/Customer-Payment.vue";
//import CustomerMandate from "@/components/Contacts/Customer/Customer-Mandate.vue";

import search from "@/components/BaseSearch.vue";
import SingleContactTable from "./singleContactTable.vue";
import { mapActions, mapGetters } from "vuex";
import BalancePortalLink from "./BalancePortalLink.vue";
export default {
  name: "SingleCustomer",
  props: {
    // isVendor: {
    //   type: Boolean,
    //   default: false,
    // },
    // totalOutstanding: {
    //   type: [Number, String],
    //   default: 0,
    // },
    // bankName: {
    //   type: String,
    //   default: "",
    // },
  },
  data() {
    return {
      tab: 0,
      customer: true,
      // accountNumber: '3214547896',
      // totalOutstanding: 1600000.00,
      // bankName: "EcoBank",
      mask: true,
      items: [
        { tab: "Invoices", content: "CustomerInvoice" },
        { tab: "Payments", content: "CustomerPayment" },
        // { tab: "Mandates", content: "CustomerMandate" },
      ],
      // customerInvoices: [],
      singleCustomerDetails: {
        id: "",
        company_name: "",
        contact_person: "",
        contact_email: "",
        contactPhone: "",
        accounting_code: "",
        default_payment_terms: "",
        tags: [],
      },
      CustomerOptions: {
        dataLabels: {
          enabled: false,
        },
        colors: ["#E3AA1C", "#16BE98", "#FF6A6A"],
        labels: ["Invoices", "Payments"],

        plotOptions: {
          pie: {
            donut: {
              size: "80%",
              labels: {
                show: true,
                name: {
                  show: true,
                  fontSize: "22px",
                  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                  fontWeight: 600,
                  color: undefined,
                  offsetY: -10,
                },
                value: {
                  show: true,
                  fontSize: "22px",
                  color: "#596A73",
                  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                  fontWeight: 700,
                  //offsetY: 16,
                  formatter: function (val) {
                    return val;
                  },
                },
                total: {
                  show: true,
                  label: "Total",
                  color: "#8F96A1",
                  fontSize: "14px",
                  formatter: function (w) {
                    return w.globals.seriesTotals.reduce(function (a, b) {
                      return a + b;
                    }, 0);
                  },
                },
              },
              legend: {
                show: true,
                position: "right",
                horizontalAlign: "center",
                fontSize: "14px",
                fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                fontWeight: 400,
                labels: {
                  colors: "#8F96A1",
                },
                markers: {
                  width: 12,
                  height: 12,
                  strokeWidth: 0,
                  strokeColor: "#fff",
                  fillColors: ["#E3AA1C", "#16BE98", "#FF6A6A"],
                  radius: 12,
                  customHTML: undefined,
                  onClick: undefined,
                  offsetX: 0,
                  offsetY: 0,
                },
                itemMargin: {
                  horizontal: 1,
                },
                formatter: function (seriesName, opts) {
                  return (
                    seriesName + ":  " + opts.w.globals.series[opts.seriesIndex]
                  );
                },
              },
            },
          },
        },
      },
      searchQuery: null,
      loadingData: false,
    };
  },
  computed: {
    ...mapGetters({
      singleCustomerInvoices: "contacts/getCustomerInvoicesById",
      paymentCount: "contacts/getContactPaymentInvoicesTotal",
      custHypnId: "contacts/getCustomerHypnId",
      orgHypnId: "organizations/getOrganizationHypnID",
      customerBankDetails: "contacts/getCustomerBankDetails",
      customerOutstandingBalanceDetails:
        "contacts/getCustomerOutstandingBalance",
      organizationId: "organizations/getOrganizationID",
    }),
    customerAnalytics() {
      return [this.singleCustomerInvoices?.length, this.paymentCount];
    },
    sumTotal() {
      return this.customerAnalytics.reduce((acc, curVal) => acc + curVal, 0);
    },
    maskedAccountnumber() {
      if (this.mask) {
        const visibleDigits = this.accountNumber?.slice(0, 4);
        const hiddenDigits = "******";
        return visibleDigits + hiddenDigits;
      } else {
        return this.accountNumber;
      }
    },

    // get the total outstanding balance
    totalOutstanding() {
      return this.customerOutstandingBalanceDetails?.balance;
    },

    // get the accountnumber and bank name
    accountNumber() {
      return this.customerBankDetails?.accountnumber;
    },

    bankname() {
      return this.customerBankDetails?.bankname;
    },
    BalancePayload() {
      return {
        orgHpynId: this.orgHypnId,
        custHypnId: this.custHypnId,
      };
    },

    balancePortalLinkText() {
      return this.orgHypnId && this.custHypnId
        ? `https://${
            process.env.NODE_ENV === "development" ||
            process.env.NODE_ENV === "staging"
              ? "dev."
              : ""
          }portal.hypn.so/${this.orgHypnId}/${this.custHypnId}/?email=${
            this.singleCustomerDetails?.contact_email
          }`
        : "";
    },
  },
  components: {
    ContactDetailsCard,
    SingleContactAnalytics,
    CustomerInvoice,
    CustomerPayment,
    // CustomerMandate,
    SingleContactTable,
    search,
    BalancePortalLink,
  },
  methods: {
    // map Actions for vuex
    ...mapActions({ showToast: "ui/showToast" }),

    formatDate(date) {
      return this.$moment(date).format("DD MMM YYYY");
    },

    // dispatch action for getCustomerPaymentInvoices from vuex using the customer id from the route
    getCustomerPaymentInvoices(id) {
      this.loadingData = true;
      try {
        this.loadingPaymentInvoices = true;

        this.$store.dispatch("contacts/getCustomerPaymentInvoices", id);
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingPaymentInvoices = false;
        this.loadingData = false;
      }
    },
    // toggle the account number visibility
    toggleMask() {
      this.mask = !this.mask;
    },

    // get the customers balance
    async getCustomerBalance(payload) {
      try {
        await this.$store.dispatch("contacts/getOutstandingBalance", payload);
      } catch (error) {
        console.log(error);
        this.showToast({
          sclass: "error",
          show: true,
          message: error.msg,
          timeout: 3000,
        });
      }
    },

    // fetch customer bank details
    async getCustomerBankDetails() {
      let organizationId = this.organizationId;
      try {
        await this.$store.dispatch("contacts/getCustomerBankDetails", {
          organizationId: organizationId,
          customerId: this.$route.params.id,
        });
      } catch (error) {
        console.log(error);
        this.showToast({
          sclass: "error",
          show: true,
          message: error.msg,
          timeout: 3000,
        });
      }
    },
    // pass the emitted search value to the searchQuery variable
    sendSearchQuery(val) {
      this.searchQuery = val;
    },

    // send portal Link
    async sendLink() {
      // console.log(this.linkText)
      const payload = {
        orgid: this.orgHypnId,
        customer: this.custHypnId,
      };
      try {
        await this.$store.dispatch("contacts/sendPortalInvitation", payload);
        this.showToast({
          sclass: "success",
          show: true,
          message: " Portal link sent to customer",
          timeout: 3000,
        });
      } catch (error) {
        console.log(error);
        this.showToast({
          sclass: "error",
          show: true,
          message: error.msg,
          timeout: 3000,
        });
      }
    },
  },
  async mounted() {
    const id = this.$route.params.id;
    await this.$store
      .dispatch("contacts/getSingleCustomer", id)
      .then((response) => {
        // console.log(JSON.stringify(response.data.data, null, 2));
        if (response.status === 200) {
          this.singleCustomerDetails.id = response.data.data.hypn_id || "";
          this.singleCustomerDetails.company_name =
            response.data.data.company?.company_name || "";
          this.singleCustomerDetails.contact_person =
            response.data.data.firstname + " " + response.data.data.lastname ||
            "";
          this.singleCustomerDetails.contact_email =
            response.data.data.email || "";
          this.singleCustomerDetails.contactPhone =
            response.data.data.phone || "";
          this.singleCustomerDetails.accounting_code = "";
          this.singleCustomerDetails.default_payment_terms =
            response.data.data.terms || "";
          this.singleCustomerDetails.tags =
            response.data.data?.tag.map((str) => str.label) || [];
        }
        this.singleCustomerDetails.date_added = this.formatDate(
          response.data.data.created_at
        );
        this.singleCustomerDetails.approved = response.data.data.approved;
        this.customerInvoices = response.data.data.invoices;
      });
  },

  watch: {
    //  watch $route.params.id for changes and call getCustomerPaymentInvoices with the handler funtion and immediately call the function
    "$route.params.id": {
      handler: function (val) {
        this.getCustomerPaymentInvoices(val);
        // this.getCustomerBankDetails(val)
      },
      immediate: true,
      deep: true,
    },

    BalancePayload: {
      handler: function (val) {
        console.log(val);
        if (val.orgHpynId && val.custHypnId) {
          this.getCustomerBalance(val);
        }
      },
      immediate: true,
      deep: true,
    },
    organizationId: {
      handler: function (val) {
        if (val) {
          this.getCustomerBankDetails();
        }
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.header__txt {
  font-size: 0.9rem;
  font-weight: 700;
  color: #19283d;
  margin-bottom: 0.5rem;
}

.value__txt {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  color: rgba(25, 40, 61, 0.8);
  padding-top: 8px;
}

.v-divider--inset:not(.v-divider--vertical) {
  max-width: calc(100% - 9px);
}

.v-application--is-ltr .v-divider--inset:not(.v-divider--vertical) {
  margin-left: 0px;
}

.v-application .elevation-3 {
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 3%), 0px 6px 10px 0px rgb(0 0 0 / 3%),
    0px 1px 18px 0px rgb(0 0 0 / 3%) !important;
}

.tabItems {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
}

.account {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: rgba(25, 40, 61, 0.8);
}
</style>
