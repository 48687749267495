export default {
  data() {
    return {
      count: 0,
      duration: 2000,
      interval: null,
    };
  },
  methods: {
    startCountUp(endCount) {
      if (this.interval) {
        clearInterval(this.interval);
      }

      this.count = endCount / 2; // Set count to half of endCount

      const increment = endCount / (this.duration / 10);
      this.interval = setInterval(() => {
        this.count += increment;
        if (this.count >= endCount) {
          clearInterval(this.interval);
          this.interval = null;
        }
      }, 10);
    },
  },
};
