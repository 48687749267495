<template>
  <div>
    <v-hover v-slot:default="{ hover }">
      <v-card
        class="px-2 py-4 my-1"
        style="cursor: pointer; transition: all 0.3s ease"
        :elevation="hover ? 2 : 0"
        :color="index % 2 ? '#f6f6f6' : ''"
        @click="
          $router.push({
            name: 'inbox.invoice',
            params: { id: id },
          })
        "
      >
        <div class="d-flex align-center flex-md-wrap">
          <v-flex md1>
            <div class="d-flex justify-start align-center">
              <span class="text-truncate table_rows">
                {{ id }}
              </span>
            </div>
          </v-flex>
          <v-flex md4>
            <div class="d-flex justify-start align-center">
              <span class="text-truncate table_rows">
                {{ refNumber }}
              </span>
            </div>
          </v-flex>
          <v-flex md3>
            <div class="d-flex justify-start align-center">
              <span class="text-truncate table_rows">
                {{ amount }}
              </span>
            </div>
          </v-flex>

          <!-- <v-flex md2>
            <div class="d-flex align-center">
              <span class="table_rows">
                <v-icon small :color="stausIcon"> mdi-circle-medium </v-icon>
                {{ status }}
              </span>
            </div>
          </v-flex> -->
          <v-flex md2>
            <div class="d-flex">
              <span class="text-truncate table_rows" style="max-width: 110px">{{
                dueDate
              }}</span>
            </div>
          </v-flex>
          <!-- <v-flex md2>
            <div class="d-flex justify-start">
              <v-chip
                :color="chipcolor"
                text-color="#2BD5AE"
                class="chiptext"
                x-small
                >{{ category }}</v-chip
              >
            </div>
          </v-flex> -->
          <v-flex md2>
            <div class="d-flex justify-end">
              <span class="text-truncate table_rows" style="max-width: 110px">{{
                entryDate
              }}</span>
            </div>
          </v-flex>
        </div>
      </v-card>
    </v-hover>
  </div>
</template>

<script>
export default {
  props: {
    index: {
      type: Number,
    },
    id: {
      type: Number,
    },
    refNumber: {
      type: String,
    },
    amount: {
      type: [String, Number],
    },
    status: {
      type: String,
    },
    dueDate: {
      type: String,
    },
    entryDate: {
      type: String,
    },
    category: {
      type: String,
    },
  },
  data() {
    return {};
  },

  computed: {
    stausIcon() {
      if (this.status === "due") {
        return "#FF6A6A";
      } else if (this.status === "sent") {
        return "#F7CA40";
      } else return "#2BD5AE";
    },
    chipcolor() {
      if (this.type === "customer") {
        return "#F9EED2";
      } else return "#D5F7EF";
    },
  },
};
</script>

<style scoped>
.v-application .elevation-2 {
  box-shadow: -6px 3px 1px -2px rgb(0 0 0 / 0%), 0px 2px 2px 0px rgb(0 0 0 / 0%),
    0px 1px 5px 0px rgb(0 0 0 / 7%) !important;
}

v-btn .button {
  transition-duration: 0.4s;
}

.table_rows {
  font-family: "inter";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #596a73;
}

v-btn .button:hover {
  background-color: #19283d;
  color: white;
}

.chiptext {
  font-size: 0.65rem;
  font-weight: 600;
}
</style>
