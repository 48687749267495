var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex align-center justify-center pt-4"},[_c('v-card',{staticClass:"px-6 py-10 rounded-lg",attrs:{"max-height":"40vh","min-width":"100%","outlined":""}},[_c('h5',{staticClass:"py-4 font-weight-bold text-subtitle-1 text-center primary--text"},[_vm._v(" "+_vm._s(_vm.cardTitle)+": "+_vm._s(_vm.refNumber)+" ")]),_c('div',{staticClass:"d-flex align-center justify-space-between pb-4"},[_c('div',[_c('p',{staticClass:"text-body-2 font-weight-bold grey--text mb-0"},[_vm._v("status")]),_c('v-chip',{staticClass:"pa-2 text-center",attrs:{"outlined":"","x-small":"","color":_vm.chipColor}},[_c('span',{staticClass:"text-caption px-2 ma-0"},[_vm._v(_vm._s(_vm.status))])])],1),_c('div',[_c('p',{staticClass:"text-center text-body-2 font-weight-bold grey--text mb-0"},[_vm._v(" Created ")]),_c('v-chip',{staticClass:"px-2 py-3",attrs:{"x-small":""}},[_c('span',{staticClass:"text-caption px-2"},[_vm._v(_vm._s(_vm.created))])])],1),_c('div',[_c('p',{staticClass:"text-right text-body-2 font-weight-bold grey--text mb-0"},[_vm._v(" Amount ")]),_c('v-chip',{staticClass:"px-2 py-3",attrs:{"x-small":""}},[_c('span',{staticClass:"text-caption px-2"},[_vm._v(_vm._s(_vm._f("currency")(_vm.total,_vm.currencyWithShortCode)))])])],1)]),(_vm.cardTitle == 'Invoices')?_c('div',{staticClass:"d-flex align-center justify-end"},[_c('div',{staticClass:"d-flex align-center mx-1"},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',{staticClass:"text-capitalize px-2 my-1 rounded",style:({
              'background-color': hover ? '#ffffff' : '#d9dee1',
              border: hover ? '' : '',
              color: hover ? 'white' : '#ffffff',
            }),attrs:{"dark":"","outlined":"","depressed":"","loading":_vm.isCanceling,"small":"","color":_vm.status === 'sent' ? 'grey' : 'primary'},on:{"click":function($event){$event.stopPropagation();return _vm.cancelCustomerInvoice.apply(null, arguments)}}},[_vm._v("Cancel")])]}}],null,false,3121757255)})],1),(_vm.status !== 'void')?_c('div',{staticClass:"d-flex align-center mx-1"},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var hover = ref.hover;
return [_c('v-btn',{staticClass:"text-capitalize px-2 my-1 rounded",style:({
              'background-color': hover ? '#c4c9cf' : '',
              border: hover ? 'none' : '',
              color: hover ? 'white' : '#ffffff',
            }),attrs:{"dark":"","outlined":"","depressed":"","loading":_vm.isSending,"small":"","color":_vm.status === 'sent' ? 'grey' : 'primary'},on:{"click":_vm.sendCustomerInvoice}},[_vm._v("Send")])]}}],null,false,1952489789)})],1):_vm._e()]):_vm._e(),(_vm.cardTitle == 'payments')?_c('div',{staticClass:"d-flex align-center justify-end"},[_c('p',{staticClass:"text-right text-body-2 font-weight-bold grey--text mb-0"},[_vm._v(" Amount ")]),_c('v-chip',{staticClass:"px-2 py-3",attrs:{"x-small":""}},[_c('span',{staticClass:"text-caption px-2"},[_vm._v(_vm._s(_vm.amount))])])],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }