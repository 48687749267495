<template>
  <v-card elevation="3">
    <v-card-title class="card__title pt-6 px-10">
      {{ cardTitle }}
    </v-card-title>
    <div class="text-center py-10" v-if="loadingData">
      <v-progress-circular
        :size="50"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
    <div v-else>
      <div class="pb-6" v-if="sumTotal > 0">
        <apexchart
          width="340"
          type="donut"
          :options="options"
          :series="series"
        ></apexchart>
      </div>
      <NoAnalyticData v-else />
    </div>
  </v-card>
</template>

<script>
import NoAnalyticData from "./noAnalyticData.vue";

export default {
  name: "singleContactAnalytics",
  props: {
    cardTitle: {
      type: String,
      required: true,
    },
    series: {
      type: Array,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },

    sumTotal: Number,
    loadingData: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      //
    };
  },
  components: { NoAnalyticData },
};
</script>

<style scoped>
.card__title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
  color: #19283d;
}

.donut-pie {
  height: 200px;
  width: 200px;
}

.v-application .elevation-3 {
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 3%), 0px 6px 10px 0px rgb(0 0 0 / 3%),
    0px 1px 18px 0px rgb(0 0 0 / 3%) !important;
}

canvas {
  width: 100%;
  height: 100%;
}
</style>
