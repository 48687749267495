<template>
  <v-card min-height="330px" elevation="3">
    <v-card
      v-if="$vuetify.breakpoint.mdAndUp"
      flat
      width="100%"
      class="d-flex justify-space-between align-center"
      style="border-bottom: 1px solid rgba(127, 145, 155, 0.3)"
    >
      <slot name="tabHeader" />
    </v-card>
    <v-card width="100%" class="pb-12" flat>
      <slot name="table" />
    </v-card>
  </v-card>
</template>

<script>
export default {
  name: "singleContactTable",
  props: {
    //
  },
  data() {
    return {
      // tab: 0,
    };
  },
};
</script>

<style scoped>
.tab__class {
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
}
.v-application .elevation-3 {
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 3%), 0px 6px 10px 0px rgb(0 0 0 / 3%),
    0px 1px 18px 0px rgb(0 0 0 / 3%) !important;
}
</style>
