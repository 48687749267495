<template>
  <div>
    <ButtonActionContainer
      :currency="$appSettings.currency"
      v-if="$appSettings && $appSettings.currency"
    />

    <v-layout
      row
      class="align-center pb-4 pt-6 px-8"
      v-if="$vuetify.breakpoint.mdAndUp"
    >
      <!-- <v-flex md1>
        <div class="d-flex align-center pa-0 ma-0">
          <p class="mb-0 mx-1 pa-0 primary--text font-weight-bold">ID</p>
        </div>
      </v-flex> -->
      <v-flex md3 class="d-flex flex-row">
        <div class="d-flex align-center">
          <p class="mb-0 ml-2 primary--text font-weight-bold">Ref. No.</p>
        </div>
      </v-flex>
      <v-flex md3>
        <div class="d-flex align-center">
          <p class="mb-0 ml-3 primary--text font-weight-bold">Status</p>
        </div>
      </v-flex>
      <v-flex md3>
        <div class="d-flex align-center">
          <p class="mb-0 ml-2 primary--text font-weight-bold">Date</p>
        </div>
      </v-flex>
      <v-flex md3>
        <div class="d-flex ms-6">
          <p class="mb-0 primary--text font-weight-bold">Amount</p>
        </div>
      </v-flex>
    </v-layout>

    <v-row
      v-if="ContactPaymentInvoicesData && ContactPaymentInvoicesData.length > 0"
    >
      <v-col
        cols="12"
        v-for="(invoice, i) in displayedItems"
        :key="i"
        class="py-0 ma-0"
      >
        <v-skeleton-loader
          class="mt-4 mt-md-0 px-6 px-md-0"
          :type="$vuetify.breakpoint.mdAndUp ? 'text' : 'card'"
          :loading="loadingPaymentInvoices"
        >
          <customerPaymentCard
            v-if="$vuetify.breakpoint.mdAndUp"
            :index="i + 1"
            :id="invoice.id"
            :amount="invoice.amount | currency(currencyWithShortCode)"
            :RefNumber="invoice.reference"
            :dueDate="invoice.date | moment('DD/MM/YYYY')"
            :status="invoice.status || ''"
          />
          <!-- card for the mobile screens -->

          <MobileTableCard
            v-if="$vuetify.breakpoint.smAndDown"
            :cardTitle="cardTitle"
            :index="i + 1"
            :id="invoice.id"
            :amount="invoice.amount | currency(currencyWithShortCode)"
            :refNumber="invoice.reference"
            :dueDate="invoice.date | moment('DD/MM/YYYY')"
          />
        </v-skeleton-loader>
      </v-col>
    </v-row>
    <div
      class="d-flex justify-center align-center pt-6"
      style="width: 100%; min-height: 200px"
      v-else
    >
      <span class="text--primary text-h5 font-monospace"
        >No Data available</span
      >
    </div>
    <!-- <pre>{{ ContactPaymentInvoicesData }}</pre>
    <pre>{{ displayedItems }}</pre> -->
    <div
      class="pagination__container px-4"
      v-if="ContactPaymentInvoicesData && ContactPaymentInvoicesData.length > 0"
    >
      <BasePagination
        :current-page="currentPage"
        :visible-pages="visiblePageValue"
        :total-pages="totalPages"
        @page-changed="handlePageChange"
      />
    </div>
  </div>
</template>

<script>
import CustomerPaymentCard from "./CustomerPaymentCard.vue";
import currencyFilter from "@/mixins/currencyFilter.js";
import pagination from "@/mixins/pagination";
import BasePagination from "@/components/BasePagination.vue";
import MobileTableCard from "./MobileTableCard.vue";
import ButtonActionContainer from "../buttonActionContainer.vue";
import { mapGetters } from "vuex";
//import { mapActions } from "vuex";
export default {
  name: "Customer-Payment",
  props: {
    searchQuery: String,
  },
  components: {
    CustomerPaymentCard,
    MobileTableCard,
    ButtonActionContainer,
    BasePagination,
  },
  mixins: [currencyFilter, pagination],

  data() {
    return {
      dialog: false,
      cardTitle: "Payments",
      currency: "NGN",
      loadingPaymentInvoices: false,
      itemsPerPage: 5,
    };
  },

  computed: {
    ...mapGetters({
      //  ContactPaymentInvoices: "contacts/getContactPaymentInvoices",
      ContactPaymentInvoicesData: "contacts/getContactPaymentInvoices",
      // ContactCurrencyType: "contacts/getCustomerCurrency",
    }),

    // filter the ContactPaymentInvoicesData array with the search variable
    // filteredPayments() {
    //   if (this.searchQuery) {
    //     const query = String(this.searchQuery).toLowerCase().split(' ');
    //     return this.ContactPaymentInvoicesData?.filter((item) => {
    //       return query.every(term => item.invoicenumber?.toLowerCase().includes(term));
    //     })

    //     // return this.$moment(item.created_at).format('DD-MM-YYYY');
    //   } else {
    //     return this.ContactPaymentInvoicesData;
    //   }
    // },

    // get the length of the filtered form array to use it as the value of the visible page
    visiblePageValue() {
      const pageNumber = Math.floor(
        this.ContactPaymentInvoicesData.length / this.itemsPerPage
      );
      return pageNumber > 5 ? 5 : pageNumber < 1 ? 1 : pageNumber;
    },

    // get array per page
    displayedItems() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      if (
        this.ContactPaymentInvoicesData &&
        this.ContactPaymentInvoicesData.length > this.itemsPerPage
      ) {
        return this.ContactPaymentInvoicesData?.slice(startIndex, endIndex);
      } else {
        return this.ContactPaymentInvoicesData?.slice();
      }
    },
    totalPages() {
      return Math.ceil(
        this.ContactPaymentInvoicesData.length / this.itemsPerPage
      );
    },
  },

  methods: {
    // dispatch action for getCustomerPaymentInvoices from vuex using the customer id from the route
    async getCustomerPaymentInvoices(id) {
      try {
        this.loadingPaymentInvoices = true;

        await this.$store.dispatch("contacts/getCustomerPaymentInvoices", id);
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingPaymentInvoices = false;
      }
    },
  },
  watch: {
    //  watch $route.params.id for changes and call getCustomerPaymentInvoices with the handler funtion and immediately call the function
    "$route.params.id": {
      handler: function (val) {
        this.getCustomerPaymentInvoices(val);
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>

<style scoped>
.switch-card {
  height: 53px;
  background: rgba(127, 145, 155, 0.052607);
}

.switch {
  width: 15.28px;
  height: 15.28px;
}

.export-btn {
  font-family: "Inter" sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  letter-spacing: 0.636364px;

  color: #19283d;
}

i.sli-font {
  font-size: 12px !important;
  display: inline-block !important;
}

.material-icons {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  color: #19283d;
}

.pagination__container {
  display: flex;
  justify-content: right;
  align-items: center;
  padding-top: 6px;
}
</style>
