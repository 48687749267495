<template>
  <div>
    <v-dialog v-model="dialog" max-width="574">
      <v-card color="#f8f7f4" class="rounded-lg">
        <v-form ref="updateCustomerForm">
          <v-card-title
            style="background: #ffffff; border-radius: 8px 8px 0px 0px"
          >
            <span
              style="
                font-family: Inter;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 19px;
                color: #19283d;
              "
              >Edit Customer</span
            >
            <v-spacer></v-spacer>
            <v-icon
              tag="button"
              @click="dialog = false"
              class="text-bolder"
              color="#596A73"
            >
              mdi-close
            </v-icon>
          </v-card-title>
          <div class="px-8 d-flex" style="background: #f3eee8">
            <v-tabs v-model="tab" background-color="#f3eee8">
              <v-tab v-for="item in CustomerTabs" :key="item">
                {{ item }}
              </v-tab>
            </v-tabs>
          </div>
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <v-card style="background-color: transparent" flat>
                <div class="my-7 mx-7 mx-md-10 mb-6">
                  <p class="pa-0 ma-0 text--disabled text-subtitle-2">
                    Include details about this customer, contact person,
                    business address, default payment terms, etc
                  </p>
                </div>

                <v-row class="mx-4 mx-md-10">
                  <v-col cols="6" class="">
                    <v-text-field
                      v-model="customerDetails.firstname"
                      background-color="#ffffff"
                      outlined
                      hide-details="auto"
                      placeholder="First Name"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" class="pl-0">
                    <v-text-field
                      v-model="customerDetails.lastname"
                      background-color="#ffffff"
                      outlined
                      hide-details="auto"
                      placeholder="Last Name"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="mx-4 mx-md-10 pl-3">
                  <v-col cols="12" class="">
                    <v-text-field
                      v-model="customerDetails.email"
                      :rules="[rules.email]"
                      background-color="#ffffff"
                      type="email"
                      outlined
                      hide-details="auto"
                      placeholder="Email"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="mx-4 mx-md-10">
                  <v-col cols="6">
                    <v-text-field
                      v-model="customerDetails.phone"
                      background-color="#ffffff"
                      outlined
                      hide-details="auto"
                      placeholder="Phone"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" class="pl-0">
                    <v-text-field
                      v-model="customerDetails.tax_number"
                      background-color="#ffffff"
                      outlined
                      hide-details="auto"
                      placeholder="Tax Number"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <div class="d-flex justify-end py-6 mb-6 px-7">
                  <v-btn
                    class="mr-5"
                    @click="switchTabs('next')"
                    dark
                    width="121"
                    height="45"
                    style="
                      background: var(--v-primary-base);
                      box-shadow: 0px 12px 22px rgba(0, 0, 0, 0.24);
                      border-radius: 4px;
                    "
                  >
                    <simple-line-icons
                      icon="arrow-right"
                      color="#FFFFFF"
                      style="
                        font-family: simple-line-icons;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 12px;
                        line-height: 16px;
                      "
                      no-svg
                    />
                    <span
                      class="text-capitalize pl-3"
                      style="
                        font-family: Inter;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 17px;
                        text-align: center;
                        letter-spacing: 0.636364px;
                        color: #ffffff;
                      "
                      >next</span
                    >
                  </v-btn>
                </div>
              </v-card>
            </v-tab-item>
            <!-- BUSINESS TAB -->
            <v-tab-item>
              <v-card style="background-color: transparent" flat>
                <div class="mt-10">
                  <v-row class="mx-4 mx-md-10">
                    <v-col>
                      <v-text-field
                        v-model="customerDetails.company.company_name"
                        background-color="#ffffff"
                        outlined
                        hide-details="auto"
                        placeholder="Company Name"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="mx-4 mx-md-10">
                    <v-col>
                      <v-text-field
                        v-model="customerDetails.address.address"
                        background-color="#ffffff"
                        outlined
                        hide-details="auto"
                        placeholder="Address"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="mx-4 mx-md-10">
                    <v-col cols="6" class="">
                      <v-text-field
                        v-model="customerDetails.address.postcode"
                        background-color="#ffffff"
                        outlined
                        hide-details="auto"
                        placeholder="Post Code"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6" class="">
                      <v-text-field
                        v-model="customerDetails.address.city"
                        background-color="#ffffff"
                        type="text"
                        outlined
                        hide-details="auto"
                        placeholder="City"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="mx-4 mx-md-10">
                    <v-col cols="6" class="">
                      <v-text-field
                        v-model="customerDetails.address.state"
                        background-color="#ffffff"
                        outlined
                        hide-details="auto"
                        placeholder="State"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6" class="">
                      <v-select
                        v-model="customerDetails.address.country"
                        :items="country"
                        background-color="#ffffff"
                        outlined
                        hide-details="auto"
                        placeholder="Country"
                      ></v-select>
                    </v-col>
                  </v-row>

                  <div class="d-flex justify-end py-6 mb-6 px-7">
                    <v-btn
                      @click="switchTabs('next')"
                      dark
                      width="121"
                      height="45"
                      class="mr-5"
                      style="
                        background: var(--v-primary-base);
                        box-shadow: 0px 12px 22px rgba(0, 0, 0, 0.24);
                        border-radius: 4px;
                      "
                    >
                      <simple-line-icons
                        icon="arrow-right"
                        color="#FFFFFF"
                        style="
                          font-family: simple-line-icons;
                          font-style: normal;
                          font-weight: normal;
                          font-size: 12px;
                          line-height: 16px;
                        "
                        no-svg
                      />
                      <span
                        class="text-capitalize pl-3"
                        style="
                          font-family: Inter;
                          font-style: normal;
                          font-weight: 500;
                          font-size: 14px;
                          line-height: 17px;
                          text-align: center;
                          letter-spacing: 0.636364px;
                          color: #ffffff;
                        "
                        >Next</span
                      >
                    </v-btn>
                  </div>
                </div>
              </v-card>
            </v-tab-item>
            <!-- Category TAB -->
            <v-tab-item>
              <v-card style="background-color: transparent" flat>
                <div class="mt-10">
                  <v-row class="mx-4 mx-md-10">
                    <v-col cols="6" class="">
                      <v-select
                        v-model="customerDetails.company.business_type"
                        :items="businessCategory"
                        background-color="#ffffff"
                        outlined
                        hide-details="auto"
                        placeholder="Business Category"
                      ></v-select>
                    </v-col>
                    <v-col cols="6" class="pl-0">
                      <v-text-field
                        v-model="customerDetails.company.registration_number"
                        background-color="#ffffff"
                        outlined
                        hide-details="auto"
                        placeholder="Registration Number"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="mx-4 mx-md-10">
                    <v-col cols="6" class="">
                      <v-select
                        v-model="customerDetails.currency"
                        :items="currency"
                        background-color="#ffffff"
                        outlined
                        hide-details="auto"
                        placeholder="Currency"
                      ></v-select>
                    </v-col>
                    <v-col cols="6" class="pl-0">
                      <v-select
                        v-model="customerDetails.company.industry"
                        :items="customerIndustry"
                        background-color="#ffffff"
                        outlined
                        hide-details="auto"
                        placeholder="Industry"
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row class="mx-4 mx-md-10">
                    <v-col md="12" class="">
                      <TagsInputComponent
                        :tagsArray="TagsArray"
                        @addTag="addTag"
                        @removeLastTag="removeLastTag"
                      />
                    </v-col>
                  </v-row>
                  <div class="d-flex justify-end py-6 mb-8 px-7">
                    <v-btn
                      @click="saveAction"
                      :loading="updatingCustomer"
                      dark
                      width="121"
                      height="45"
                      class="mr-5"
                      style="
                        background: var(--v-primary-base);
                        box-shadow: 0px 12px 22px rgba(0, 0, 0, 0.24);
                        border-radius: 4px;
                      "
                    >
                      <simple-line-icons
                        icon="arrow-right"
                        color="#FFFFFF"
                        style="
                          font-family: simple-line-icons;
                          font-style: normal;
                          font-weight: normal;
                          font-size: 12px;
                          line-height: 16px;
                        "
                        no-svg
                      />
                      <span
                        class="text-capitalize pl-3"
                        style="
                          font-family: Inter;
                          font-style: normal;
                          font-weight: 500;
                          font-size: 14px;
                          line-height: 17px;
                          text-align: center;
                          letter-spacing: 0.636364px;
                          color: #ffffff;
                        "
                        >Update</span
                      >
                    </v-btn>
                  </div>
                </div>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import contactDetails from "@/mixins/contactDetails.js";
import TagsInputComponent from "@/components/TagsInputComponent.vue";
export default {
  data() {
    return {
      dialog: false,
      updatingCustomer: false,
      customerDetails: {
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        tax_number: "",
        company: {
          company_name: "",
          registration_number: "",
          business_type: "",
          industry: "",
        },
        address: {
          address: "",
          city: "",
          state: "",
          country: "",
          postcode: "",
        },
        // terms: "",
        currency: "",
      },
      fullname: "",
      organization: "",
      TagsArray: [],

      tab: null,

      CustomerTabs: ["Contact", "Business", "Category"],
      rules: {
        required: (v) => !!v || "This field is required",
        email: (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      },
    };
  },
  components: { TagsInputComponent },
  mixins: [contactDetails],
  // computed: {

  // },
  methods: {
    ...mapActions({ showToast: "ui/showToast" }),
    switchTabs(direction) {
      // console.log(direction);
      switch (direction) {
        case "next":
          if (this.tab <= this.CustomerTabs.length - 2) {
            this.tab++;
            return;
          }
          if (this.tab == this.CustomerTabs.length - 1) {
            this.saveAction();
          }
          break;

        default:
          break;
      }
    },
    async saveAction() {
      const id = this.$route.params.id;
      // console.log(this.customerCompleteDetails, null, 2);

      const newTagArray = this.TagsArray.reduce((acc, cur) => {
        acc.push({ label: cur, tag_name: cur });
        return acc;
      }, []);
      //  console.log(JSON.stringify(newTagArray, null, 2));
      let data = {
        ...this.customerDetails,
        tag: newTagArray,
        organization: this.organization,
        // terms: this.selectedPaymentTerms.replace("Net ", ""),
      };
      //  console.log(JSON.stringify(data, null, 2));
      if (this.$refs.updateCustomerForm.validate()) {
        try {
          this.updatingCustomer = true;
          await this.$store
            .dispatch("contacts/updateCustomerDetails", {
              id,
              payload: data,
            })
            .then(() => {
              this.getCustomerDetails();
            });

          this.showToast({
            sclass: "success",
            show: true,
            message: "Customer details Updated successfully..",
            timeout: 3000,
          });

          this.dialog = false;
        } catch (error) {
          console.log(error);
          this.showToast({
            sclass: "error",
            show: true,
            message: "failed to Update",
            timeout: 3000,
          });
        } finally {
          this.updatingCustomer = false;
        }
      }
    },
    async getCustomerDetails() {
      const id = this.$route.params.id;
      await this.$store
        .dispatch("contacts/getSingleCustomer", id)
        .then((response) => {
          console.log(JSON.stringify(response.data.data, null, 2));
          if (response.status == 200) {
            this.customerDetails.firstname = response.data.data.firstname;
            this.customerDetails.lastname = response.data.data.lastname;
            this.customerDetails.email = response.data.data.email;
            this.customerDetails.phone = response.data.data.phone;
            this.customerDetails.tax_number = response.data.data.tax_number;
            this.customerDetails.currency = response.data.data.currency;
            this.customerDetails.company.company_name =
              response.data.data.company?.company_name;
            this.customerDetails.company.country =
              response.data.data.company?.country;
            this.customerDetails.company.registration_number =
              response.data.data.company?.registration_number;
            this.customerDetails.company.business_type =
              response.data.data.company?.business_type;
            this.customerDetails.address.address =
              response.data.data.address?.address;
            this.customerDetails.address.city =
              response.data.data.address?.city;
            this.customerDetails.address.state =
              response.data.data.address?.state;
            this.customerDetails.address.country =
              response.data.data.address?.country;
            this.customerDetails.address.postcode =
              response.data.data.address?.postcode;
            this.organization = response.data.data.organization;
            this.TagsArray = response.data.data.tag.map((str) => str.label);
          }
        });
    },
    show(value) {
      this.dialog = value;
      this.getCustomerDetails();
    },

    //Add new Tags to the TagsArray
    addTag(value) {
      let val = value.trim();
      this.TagsArray.push(val);
      // console.log(JSON.stringify(this.TagsArray));
    },

    //Remove Tags from the TagsArray
    removeLastTag() {
      this.TagsArray.splice(this.TagsArray.length - 1, 1);
    },
  },
  mounted() {},
};
</script>

<style scoped>
.chipText {
  font-family: "Inter";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #7f919b;
}

.headerText {
  margin-left: 52px;
  font-family: "Inter";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.45px;
  color: #7f919b;
}

i.sli-font {
  font-size: 12px;
  display: inline-block;
}

.select {
  margin-right: 260px;
  margin-left: 52px;
}

.theme--light.v-tabs-items {
  background-color: transparent !important;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
  padding-left: 0;
}
</style>
