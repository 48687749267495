<template>
  <div>
    <ButtonActionContainer :currency="currency" />
    <div
      class="d-flex align-center my-2 px-2"
      v-if="$vuetify.breakpoint.mdAndUp"
    >
      <v-flex md1 class="d-flex flex-row">
        <div class="d-flex align-center">
          <p class="mb-0 primary--text font-weight-bold">#</p>
        </div>
      </v-flex>
      <v-flex md4 class="d-flex flex-row">
        <div class="d-flex align-center">
          <p class="mb-0 primary--text font-weight-bold">Ref. No.</p>
        </div>
      </v-flex>
      <v-flex md3 class="d-flex flex-row">
        <div class="d-flex align-center">
          <p class="mb-0 primary--text font-weight-bold">Amount</p>
        </div>
      </v-flex>
      <!-- <v-flex md2>
        <div class="d-flex align-center">
          <p class="mb-0 primary--text font-weight-bold">Status</p>
        </div>
      </v-flex> -->
      <v-flex md2>
        <div class="d-flex align-center">
          <p class="mb-0 primary--text font-weight-bold">Due Date</p>
        </div>
      </v-flex>
      <!-- <v-flex md2>
        <div class="d-flex">
          <p class="mb-0 primary--text font-weight-bold">Category</p>
        </div>
      </v-flex> -->
      <v-flex md2>
        <div class="d-flex justify-end">
          <p class="mb-0 primary--text font-weight-bold">Entry Date</p>
        </div>
      </v-flex>
    </div>

    <div v-if="vendorInvoices && vendorInvoices.length > 0">
      <div v-for="(invoice, i) in displayedItems" :key="i" class="py-0 ma-0">
        <vendorInvoiceCard
          v-if="$vuetify.breakpoint.mdAndUp"
          :index="i + 1"
          :id="invoice.id"
          :refNumber="invoice.invoicenumber"
          :amount="invoice.total | currency(currencyNoSymbol)"
          :dueDate="invoice.due_date | moment('DD/MM/YYYY')"
          :entryDate="invoice.created_at | moment('DD/MM/YYYY')"
        />
        <vendorMobileTableCard
          v-if="$vuetify.breakpoint.smAndDown"
          :index="i + 1"
          :id="invoice.id"
          :outstanding="invoice.outstanding"
          :refNumber="invoice.invoicenumber"
          :cardTitle="cardTitle"
          :status="invoice.status"
          :dueDate="invoice.date | date"
        />
      </div>
    </div>
    <div class="d-flex justify-center align-center pt-6" v-else>
      <span class="text--primary text-h5 font-monospace"
        >No Data available</span
      >
    </div>
    <div
      class="pagination__container px-4"
      v-if="vendorInvoices && vendorInvoices.length > 0"
    >
      <BasePagination
        :current-page="currentPage"
        :visible-pages="visiblePageValue"
        :total-pages="totalPages"
        @page-changed="handlePageChange"
      />
    </div>
  </div>
</template>

<script>
import vendorInvoiceCard from "./vendorInvoiceCard.vue";
import ButtonActionContainer from "../buttonActionContainer.vue";
import { mapGetters } from "vuex";
import vendorMobileTableCard from "@/components/Contacts/vendor/vendorMobileTableCard.vue";
import currencyFilter from "@/mixins/currencyFilter";
import pagination from "@/mixins/pagination";
import BasePagination from "@/components/BasePagination.vue";

export default {
  name: "vendor-Invoice",
  components: {
    vendorInvoiceCard,
    ButtonActionContainer,
    vendorMobileTableCard,
    BasePagination,
  },
  props: {
    searchQuery: String,
  },
  data() {
    return {
      dialog: false,
      cardTitle: "Invoices",
      currency: "NGN",
      itemsPerPage: 5,
    };
  },
  computed: {
    ...mapGetters({
      vendorInvoices: "contacts/getVendorInvoices",
      organID: "organizations/getOrganizationID",
    }),

    // filter the vendorInvoices array with the search variable
    filteredInvoices() {
      if (this.searchQuery) {
        const query = String(this.searchQuery).toLowerCase().split(" ");
        return this.vendorInvoices?.filter((item) => {
          return query.every((term) =>
            item.invoicenumber?.toLowerCase().includes(term)
          );
        });

        // return this.$moment(item.created_at).format('DD-MM-YYYY');
      } else {
        return this.vendorInvoices;
      }
    },

    // get the length of the filtered form array to use it as the value of the visible page
    visiblePageValue() {
      const pageNumber = Math.floor(
        this.filteredInvoices.length / this.itemsPerPage
      );
      return pageNumber > 5 ? 5 : pageNumber < 1 ? 1 : pageNumber;
    },

    // get array per page
    displayedItems() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      if (
        this.filteredInvoices &&
        this.filteredInvoices.length > this.itemsPerPage
      ) {
        return this.filteredInvoices.slice(startIndex, endIndex);
      } else {
        return this.filteredInvoices.slice();
      }
    },
    totalPages() {
      return Math.ceil(this.filteredInvoices.length / this.itemsPerPage);
    },
  },
  mixins: [currencyFilter, pagination],
  methods: {
    async getVendorInvoices() {
      try {
        const vendorId = this.$route.params.id;

        // dispatch the action to fetch the customer invoices only when the organization id is not null or undefined
        if (this.organID) {
          await this.$store.dispatch("contacts/getVendorInvoices", vendorId);
        }
      } catch (error) {
        console.log(error);
      }
    },

    onChangePage(pageOfItems) {
      // update page of items
      this.pageOfItems = pageOfItems;
    },
  },

  mounted() {
    // this.getVendorInvoices();
  },

  watch: {
    // watch the organId if its not null or undefined, then fetch the customer invoices
    organID: {
      handler() {
        if (this.organID) {
          this.getVendorInvoices();
        }
      },
      immediate: true,
      deep: true,
    },

    // watch the route params if the id changes, then fetch the customer invoices
    "$route.params.id": {
      handler() {
        this.getVendorInvoices();
      },

      deep: true,
      immediate: true,
    },
  },
};
</script>

<style scoped>
.switch-card {
  height: 53px;
  background: rgba(127, 145, 155, 0.052607);
}

.switch {
  width: 15.28px;
  height: 15.28px;
}

.export-btn {
  font-family: "Inter" sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  letter-spacing: 0.636364px;

  color: #19283d;
}

i.sli-font {
  font-size: 12px !important;
  display: inline-block !important;
}

.material-icons {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  color: #19283d;
}
.pagination__container {
  display: flex;
  justify-content: right;
  align-items: center;
  padding-top: 6px;
}
</style>
